import React, { useState, useEffect } from "react";
import MessageIcon from "./MessageIcon";
import { useMessageStore } from './ChatbotWidget';
import { IDocument, DocumentList } from "./DocumentList";


interface IMessage {
    questionNumber: number;
    user: string;
    text: string;
    timestamp: string;
    documents: Array<IDocument>;
    isDotsMessage: boolean;
    toBeDynamicallyShowed: boolean;
    isCommandMessage?: boolean;
}


const Message = (props: IMessage) => {
    const [responseText, setResponseText] = useState<string>(null);
    const [responseDocuments, setResponseDocuments] = useState<Array<IDocument>>([]);
    const secondaryClass = props.user == "bot" ? "bot" : "user";

    function renderMessageGradually(message: string) {

        function renderNextWord() {
            if ( index < words.length ) {
                newText += (index != 0) ? " "+words[index] : words[index];
                setResponseText(newText);
                index++;
                setTimeout(renderNextWord, 100);
            }
        }

        let words = message.split(" ");
        let index = 0;
        let newText = "";  
        renderNextWord();
    }

    useEffect( () => {
        if ( props.toBeDynamicallyShowed ) {
            renderMessageGradually(props.text);
        }
        else {
            setResponseText(props.text);
            if ( props.documents.length > 0 ) {
                setResponseDocuments(props.documents);
            }
        }
    }, [props.text] );

    useEffect( () => {
        if ( props.toBeDynamicallyShowed && props.text == responseText ) {
            useMessageStore.setState({
                messages: useMessageStore.getState().messages.map( message => {
                    if ( message.questionNumber == props.questionNumber ) {
                        message.toBeDynamicallyShowed = false;
                    }
                    return message;
                } )
            });
            if ( props.documents.length > 0 ) {
                setResponseDocuments(props.documents)
            }
        }
    }, [responseText] );

    useEffect( () => {
        if ( props.toBeDynamicallyShowed && props.text == responseText ) {
            useMessageStore.setState({
                messages: useMessageStore.getState().messages.map( message => {
                    if ( message.questionNumber == props.questionNumber ) {
                        message.toBeDynamicallyShowed = false;
                    }
                    return message;
                } )
            });
            if ( props.documents.length > 0 ) {
                setResponseDocuments(props.documents)
            }
        }
    }, [responseDocuments] );

    if ( props.isDotsMessage === true ) {
        return (
            <div className={`chatbot-message-box chatbot-${secondaryClass}-message`}>
                <MessageIcon user={props.user} />
                <div className={`chatbot-${secondaryClass}-message-body`}>
                    <div className="chatbot-message-content">
                        <div className="chatbot-message-text">
                            <span key="key-dot1" className="loader-dots loader-dot1"></span>
                            <span key="key-dot2" className="loader-dots loader-dot2"></span>
                            <span key="key-dot3" className="loader-dots loader-dot3"></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if ( props.isCommandMessage === true ) {
        return (
            <div className={`chatbot-message-box chatbot-${secondaryClass}-message`}>
                <div className="chatbot-bot-command-message">
                    {props.text}
                </div>
            </div>
        );
    }

    return (
        <div className={`chatbot-message-box chatbot-${secondaryClass}-message`}>
            <MessageIcon user={props.user} />
            <div className={`chatbot-${secondaryClass}-message-body`}>
                <div className="chatbot-message-content">
                    <div className="chatbot-message-text">{responseText}</div>
                    { responseDocuments.length > 0 &&
                        <DocumentList documents={responseDocuments} />
                    }
                </div>
                <div className={`chatbot-${secondaryClass}-message-timestamp`}>{props.timestamp}</div>
            </div>
        </div>
    );
};

export { IMessage, Message };