import {combineReducers} from 'redux';
import {genericReducer} from './Generic/GenericReducer';
import {GenericState} from './Generic/GenericState';
import {userReducer} from './User/UserReducer';
import {UserState} from './User/UserState';

const rootReducer = combineReducers({
  user: userReducer,
  generic: genericReducer
});

export interface GlobalState {
  user: UserState;
  generic: GenericState;
}

export default rootReducer;