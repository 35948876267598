import React, { useState, useEffect } from 'react';
import { SharedState } from '@Eni/docware-fe-master';
import { useLocation } from "react-router-dom";
import { IconButton } from '@fluentui/react';
import * as _ from 'lodash';
import { create } from 'zustand'
import { IMessage } from './Message';
import Chatbot from './Chatbot';
import './ChatbotWidget.scss';


interface IChatbotUser {
    id: string,
    firstName: string,
    lastName: string,
    initials: string
}


interface IMessageStore {
    messages: Array<IMessage>;
}

const useMessageStore = create<IMessageStore>()( () => ( { messages: [] } ) );


const ChatbotWidget = (props: any) => {
    const [widgetVisible, setWidgetVisible] = useState<string>("none");
    const [chatbotVisible, setChatbotVisible] = useState<boolean>(false);

    let chatbotUser = getChatbotUser();

    // Show widget only when the url path includes "report-assistant"
    let pathname = useLocation().pathname;
    useEffect(() => {
        setWidgetVisible( pathname.includes("report-assistant") ? "flex" : "none" );
    }, [pathname]);

    return (
        <div>
            { _.get(chatbotUser, 'id', null) &&
            <div className="chatbot-widget" style={{ display: `${widgetVisible}` }} >
                <IconButton
                    className="chatbot-widget-icon"
                    styles={ { icon: { fontSize: !chatbotVisible ? 40 : 20, paddingLeft: !chatbotVisible ? '5%' : '0%' } } }
                    iconProps={ { iconName: !chatbotVisible ? "ChatBot" : "ChromeClose" } }
                    onClick={ () => setChatbotVisible(!chatbotVisible) }
                /> 
                <Chatbot isVisible={chatbotVisible} user={chatbotUser} />
            </div>
            }
        </div>
    );
};


const getChatbotUser = () => {
    // Getting userId from SharedState or localStorage
    let chatbotUser: IChatbotUser = { id: null, firstName: null, lastName: null, initials: "--" };
    if ( _.has(SharedState, 'loggedUser') ) {
        SharedState.loggedUser.subscribe( user => {
            chatbotUser.id = user.userId;
            chatbotUser.firstName = user.firstName;
            chatbotUser.lastName = user.lastName;
        });
    }
    //console.log("getChatbotUser - SharedState - "+_.get(chatbotUser, 'id', null));
    let local_storage_logged_user = JSON.parse(localStorage.getItem('logged-user'));
    //console.log("getChatbotUser - localStorage1 - "+_.get(local_storage_logged_user, 'id', null));
    //console.log("getChatbotUser - localStorage2 - "+_.get(local_storage_logged_user, 'user_id', null));
    chatbotUser.id = chatbotUser.id == null ? _.get(local_storage_logged_user, 'id', null) : chatbotUser.id;
    chatbotUser.id = chatbotUser.id == null ? _.get(local_storage_logged_user, 'user_id', null) : chatbotUser.id;
    chatbotUser.firstName = chatbotUser.firstName == null ? _.get(local_storage_logged_user, 'firstName', null) : chatbotUser.firstName;
    chatbotUser.lastName = chatbotUser.lastName == null ? _.get(local_storage_logged_user, 'lastName', null) : chatbotUser.lastName;
    chatbotUser.initials = chatbotUser.firstName != null && chatbotUser.lastName != null ?
        chatbotUser.firstName.charAt(0) + chatbotUser.lastName.charAt(0) : "--";

    return chatbotUser;
}


export {
    useMessageStore,
    IChatbotUser,
    ChatbotWidget
};