import React, { useState, useEffect } from "react";
import { ChatbotMode } from "../../Services/Controllers/ChatBotController";


interface ICommand {
    name: string,
    description: string,
    messageText?: string,
    mode?: ChatbotMode
}


interface ICommandList {
    text: string,
    commandAction: (command: ICommand) => void
}


const commands: Array<ICommand> = [
    {
        name: "/enable scientific literature",
        description: "The Virtual Assistant will generate an answer based on scientific literature only.",
        messageText: "Scientific literature mode enabled",
        mode: ChatbotMode.ScientificLiterature
    },
    {
        name: "/enable technical documents",
        description: "The Virtual Assistant will generate an answer based on technical documents only.",
        messageText: "Technical documents mode enabled",
        mode: ChatbotMode.TechnicalDocuments
    },
    {
        name: "/enable all",
        description: "The Virtual Assistant will generate an answer based on all resources.",
        messageText: "All resources mode enabled",
        mode: ChatbotMode.AllResources
    },
    {
        name: "/reset session",
        description: "Start a new chat session."
    }
];


function CommandList(props: ICommandList) {
    const [visibleCommands, setVisibleCommands] = useState<Array<ICommand>>([]);

    useEffect(() => {
        setVisibleCommands(
            commands.filter( command => props.text && command.name.startsWith(props.text) )
        );
    }, [props.text]);

    if ( visibleCommands.length == 0 ) {
        return null;
    }
    return (
        <div id="chatbot-commands">
            { visibleCommands.map( (command, i) => {
                return (
                    <div key={"chatbot-command-"+i} id={"chatbot-command-"+i} className='chatbot-command'
                        title={command.description} // Tooltip
                        onClick={ (event) => props.commandAction(command) }
                    >
                        {command.name}
                    </div>
                );
            }) }
        </div>
    );
}


export {
    ICommand,
    ICommandList,
    commands,
    CommandList
}