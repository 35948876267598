import React from "react";
import { Icon } from '@fluentui/react';
import { Persona, PersonaPresence, PersonaSize } from "@fluentui/react";


interface IMessageIcon {
    user: string;
}


const MessageIcon = (props: IMessageIcon) => {
    if ( props.user == "bot" ) {
        return ( <Icon className="chatbot-bot-message-icon" iconName="ChatBot" /> );
    }
    else {
        return (
            <Persona
                imageInitials={props.user}
                size={PersonaSize.size40}
                hidePersonaDetails={true}
                imageAlt=""
            />
        );
    }
}

export default MessageIcon;