import {InputInfosActionType, SET_WAITING_LOG_IN} from './GenericAction';
import {GenericState} from './GenericState';

const DEFAULT_GENERIC_STATE: GenericState = {
  waitingLogIn: false,
};

export const genericReducer = (state: GenericState = DEFAULT_GENERIC_STATE, action: InputInfosActionType): GenericState => {
  switch (action.type) {
    case SET_WAITING_LOG_IN:
      return {
        ...state,
        waitingLogIn: action.payload,
      };
    default:
      return state;
  }
};
