import './App.scss';
import { ProviderWrapper } from './Components/ProviderWrapper/ProviderWrapper';
import { ChatbotWidget } from "./Components/ChatBot/ChatbotWidget";

export const App = () => {

  return (
    <ProviderWrapper>
      <ChatbotWidget />
    </ProviderWrapper>
  );
};
