import { Link } from '@fluentui/react';
import { ChatBotController } from '../../Services/Controllers/ChatBotController';


interface IDocument {
    document_filename: string,
    url: string
}


interface IDocumentList {
    documents: Array<IDocument>
}

function getDocText(docName: string) {
    if ( docName.length > 20 ) {
        docName = docName.slice(0, 15) + "... ." + docName.split(".").pop();
    }
    return docName;
}

function DocumentList(props: IDocumentList) {
    return (
        <div className="chatbot-message-links">
            { props.documents.map( (doc, i) => {
                return (
                    <Link key={"key-doc-link-"+i} href={doc.url} target='_blank' className='chatbot-message-link'
                        title={doc.document_filename} // Tooltip
                        onClick={ (event) => {
                            if ( ! doc.url.includes('docware.eni.com/drive') ) {
                                event.preventDefault(); // Do not follow href
                                ChatBotController.downloadFile( {fileUrl: doc.url, fileName: doc.document_filename} );
                            }
                        }}
                    >
                        {getDocText(doc.document_filename)}
                    </Link>
                );
            }) }
        </div>
    );
}


export {
    IDocument,
    IDocumentList,
    DocumentList
}