import {
  createEmptyRequest,
  AjaxService,
  IAPIRequest,
  IAPIResponse,
} from "../AjaxService";
import axios from 'axios';


enum ChatbotMode {
  ScientificLiterature = 'scientificLiterature',
  TechnicalDocuments = 'technicalDocuments',
  AllResources = 'allResources'
}

interface IChatBotApiParams {
  chat_session?: string;
  user_id: string;
  text: string;
  mode: ChatbotMode
}


const ChatBotController = {
    askQuestion: async ( params: IChatBotApiParams, callback?: (response: any) => void ) => {
        let request: IAPIRequest = createEmptyRequest();
        request.alternative_base_url = "/chatbot-core-api/docware-chatbot";
        request.url = '/core';
        request.method = 'POST';
        request.payload = params;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
          callback(response);
        }
        return response;
    },
    downloadFile: async ( params: any, callback?: (response: any) => void ) => {
        try {
            const res = await axios.get( params.fileUrl, { responseType: 'blob' });

            // Create a URL for the blob data
            const url = window.URL.createObjectURL(new Blob([res.data]));

            // Create a temporary link element and trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', params.fileName);
            document.body.appendChild(link);
            link.click();

            // Clean up the URL and link
            window.URL.revokeObjectURL(url);
            link.parentNode.removeChild(link);

            if (callback) {
              callback(res);
            }
        }
        catch (error) {
            console.error('Error downloading the file:', error);
        }
    }
};

export {
    ChatbotMode,
    ChatBotController
}