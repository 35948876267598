import React, { useState, useEffect, useRef } from "react";
import * as _ from 'lodash';
import { DateTime } from "luxon";
import ColoredIcons from "../../Utils/base64Icons";
import { IChatbotUser, useMessageStore } from './ChatbotWidget';
import { ChatbotMode, ChatBotController } from "../../Services/Controllers/ChatBotController";
import { IAPIResponse } from "../../Services/AjaxService";
import { Message } from "./Message";
import { IDocument } from "./DocumentList";
import { IconButton, Link } from '@fluentui/react';
import { ICommand, commands, CommandList } from "./CommandList";


interface IChatbotResponse {
    answerToQuestionNumber: number,
    text: string,
    documents: Array<IDocument>
}


interface IChatbotProps {
    isVisible: boolean,
    user: IChatbotUser
}


const Chatbot = (props: IChatbotProps) => {
    const [chatbotDisplay, setChatbotDisplay] = useState<string>("none");
    const [width, setWidth] = useState<number>(370);
    const [height, setHeight] = useState<number>(480);
    const [textInput, setTextInput] = useState<string>(null);
    const [chatSession, setChatSession] = useState<string>(null);
    const [chatbotMode, setChatbotMode] = useState<ChatbotMode>(ChatbotMode.TechnicalDocuments);
    const [showDotsMessage, setShowDotsMessage] = useState<boolean>(false);
    const [chatbotResponse, setChatbotResponse] = useState<IChatbotResponse>(null);
    const [lastQuestionNumber, setLastQuestionNumber] = useState<number>( () => {
        let messages = useMessageStore.getState().messages;
        let lastMessage = messages.reverse().find((msg) => msg.questionNumber != -1);
        return lastMessage ? lastMessage.questionNumber : -1;
    });
    const chatbotBodyRef = useRef(null);
    const textInputRef = useRef(null);

    // Function to scroll to the bottom of the chatbot body
    const scrollToBottom = () => {
        setTimeout( () =>  {
            if ( chatbotBodyRef.current ) {
                const bodyElement = chatbotBodyRef.current;
                bodyElement.style.scrollBehavior = 'smooth'; // Enable smooth scrolling
                bodyElement.scrollTop = bodyElement.scrollHeight;
                bodyElement.style.scrollBehavior = 'auto'; // Reset scroll behavior
            }
        }, 100 );
    }; 
    
    const getMessageTimestamp = (): string => {
        const now = DateTime.now();
        const currentTime = now.c.hour + ":" + (now.c.minute.toString().length == 1 ? "0"+now.c.minute : now.c.minute);
        return currentTime;
    }

    const horizontalHandleResize = (e: any) => {
        const newWidth = window.innerWidth - e.clientX;
        setWidth(newWidth);
    };

    const verticalHandleResize = (e: any) => {
        const newHeight = window.innerHeight - e.clientY;
        setHeight(newHeight);
    };

    const newChatSession = () => {
        // Setting the default chatbot mode
        setChatbotMode(ChatbotMode.TechnicalDocuments);

        useMessageStore.setState({
            messages: [
                {
                    questionNumber: 0,
                    user: "bot",
                    text: "Welcome to Docware Virtual Assistant! How can I help you?",
                    timestamp: getMessageTimestamp(),
                    documents: [],
                    isDotsMessage: false,
                    toBeDynamicallyShowed: false
                },
                {
                    questionNumber: -1,
                    user: 'bot',
                    text: commands.find(c => c.name == "/enable technical documents").messageText,
                    timestamp: "",
                    documents: [],
                    isDotsMessage: false,
                    toBeDynamicallyShowed: false,
                    isCommandMessage: true
                }
            ]
        });

        setLastQuestionNumber(0);
    }

    const commandAction = (command: ICommand) => {
        if ( command.name === "/reset session" ) {
            setChatSession(null);
            useMessageStore.setState({ messages: [] });
            setShowDotsMessage(false);

            newChatSession();
        }
        else {
            setChatbotMode(command.mode);

            useMessageStore.setState({ messages: [
                ...useMessageStore.getState().messages,
                {
                    questionNumber: -1,
                    user: 'bot',
                    text: command.messageText,
                    timestamp: "",
                    documents: [],
                    isDotsMessage: false,
                    toBeDynamicallyShowed: false,
                    isCommandMessage: true
                }
            ] });

            // If the user was still waiting for an answer, ignore it.
            if ( showDotsMessage ) {
                setLastQuestionNumber(lastQuestionNumber + 1);
                setShowDotsMessage(false);
            }
        }
    
        setTextInput("");
        textInputRef.current.focus();
    }

    const handleUserMessage = async (text: string) => {
        
        // Go on only if there is actually some text
        if (text.trim() == "") {
            return;
        }

        // Clean text input
        setTextInput("");

        // Is it a command?
        let command = commands.find(c => c.name === text.trim());
        if ( command ) {
            commandAction(command);
            return;
        }

        const questionNumber: number = lastQuestionNumber + 1;

        useMessageStore.setState({ messages: [
            ...useMessageStore.getState().messages,
            {
                questionNumber: questionNumber,
                user: props.user.initials,
                text,
                timestamp: getMessageTimestamp(),
                documents: [],
                isDotsMessage: false,
                toBeDynamicallyShowed: false
            }
        ] });

        setLastQuestionNumber(questionNumber);

        setShowDotsMessage(true);

        let params = {
            chat_session: chatSession,
            user_id: props.user.id,
            text,
            mode: chatbotMode
        };
        ChatBotController.askQuestion( params, (response: IAPIResponse) => {
            if ( response.error == null ) {
                setChatbotResponse({
                    answerToQuestionNumber: questionNumber,
                    text: response.payload.text,
                    documents: response.payload.documents.length > 0 ? response.payload.documents : []
                });
                if ( !chatSession ) {
                    setChatSession(response.payload.chat_session);
                }
            }
            else {
                console.log(response.error);
                setTimeout( () => {
                    setChatbotResponse({
                        answerToQuestionNumber: questionNumber,
                        text: "Something went wrong",
                        documents: []
                    });
                }, 1000 );
            }
        });
    };

    useEffect( () => {
        if ( chatbotResponse != null && _.get(chatbotResponse, 'answerToQuestionNumber', null) == lastQuestionNumber ) {
            setShowDotsMessage(false);

            useMessageStore.setState({ messages: [
                ...useMessageStore.getState().messages,
                {
                    questionNumber: lastQuestionNumber,
                    user: 'bot',
                    text: _.get(chatbotResponse, 'text', 'Something went wrong'),
                    timestamp: getMessageTimestamp(),
                    documents: chatbotResponse.documents,
                    isDotsMessage: false,
                    toBeDynamicallyShowed: true       
                }
            ] });

            setChatbotResponse(null);
        }
    }, [chatbotResponse] );

    useMessageStore.subscribe( (state, previousState) => {
        if ( state.messages.length > previousState.messages.length ) {
            scrollToBottom()
        }
    });

    useEffect(() => {
        setChatbotDisplay(props.isVisible ? "flex" : "none");
        if (props.isVisible) {
            if ( textInputRef.current ) {
                setTimeout(() => {
                    textInputRef.current.focus();
                }, 10);
            }
            scrollToBottom();
        }
    }, [props.isVisible]);

    // At initial rendering
    useEffect(() => {
        newChatSession();
    }, []);

    return (
        <div className="chatbot-box" style={{ display: `${chatbotDisplay}`, width: `${width}px`, height: `${height}px` }}>
            <div className="horizontal-resize-handle"
                onMouseDown={(e) => {
                    document.addEventListener('mousemove', horizontalHandleResize);
                    document.addEventListener('mouseup', () => {
                        document.removeEventListener('mousemove', horizontalHandleResize);
                    });
                }}
            />
            <div className="vertical-resize-handle"
                onMouseDown={(e) => {
                    document.addEventListener('mousemove', verticalHandleResize);
                    document.addEventListener('mouseup', () => {
                        document.removeEventListener('mousemove', verticalHandleResize);
                    });
                }}
            />
            <div className="chatbot-header">
                <img className="chatbotIcon" src={ColoredIcons["eniLogo"]} alt="profile" />
                <div className="chatbotTitle">Docware</div>
                <div className="chatbotSubTitle">Virtual Assistant</div>
            </div>
            <div className="chatbot-body" ref={chatbotBodyRef}>
                { useMessageStore.getState().messages.map( (message, index) => (
                    <Message
                        key={"message-"+index}
                        questionNumber={message.questionNumber}
                        user={message.user}
                        text={message.text}
                        timestamp={message.timestamp}
                        documents={message.documents}
                        isDotsMessage={false}
                        toBeDynamicallyShowed={message.toBeDynamicallyShowed}
                        isCommandMessage={message.isCommandMessage??false}
                    />
                ))}
                { showDotsMessage && (
                    <Message
                        key="dotsMessage"
                        questionNumber={-1}
                        user="bot"
                        text=""
                        timestamp=""
                        documents={[]}
                        isDotsMessage={true}
                        toBeDynamicallyShowed={false}
                    />
                )}
            </div>
            <CommandList text={textInput??""} commandAction={commandAction} />
            <div id="chatbot-terms">
                AI generated content can have mistakes. 
                Make sure it's accurate and appropriate before using it.
                <br></br>
                <Link href="https://www.google.it" target='_blank' id='terms-link'>
                    Read overview terms
                </Link>
            </div>
            <div className="chatbot-footer">
                <textarea
                    ref={textInputRef}
                    value={textInput??""}
                    className="chatbotTextInput"
                    placeholder="Ask Docware Chatbot or type '/' for commands"
                    onKeyDown={ (e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault(); // Do not go to the next line
                            handleUserMessage(e.currentTarget.value);
                        }
                    }}
                    onChange={ (e) => {
                        setTextInput(e.currentTarget.value);
                    }}
                />
                <IconButton
                    className="chatbotSendButton"
                    iconProps={ { iconName: "send" } }
                    styles={{
                        icon: { color: 'gray', fontSize: 30 }
                    }}
                    onClick={ (e) => {
                        handleUserMessage(textInput??"");
                    }}
                />
            </div>
        </div>
      );
};

export default Chatbot;