import * as React from 'react';
import {Provider} from 'react-redux';
import store from '../../Reducers/Store';
import {BrowserRouter as Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';

export const ProviderWrapper = (props: any) => {
  const history = createBrowserHistory();

  return (
    <React.StrictMode>
      <Provider store={store}>
        <Router>{props.children}</Router>
      </Provider>
    </React.StrictMode>
  );
};
